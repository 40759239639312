// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Browser from "meros/browser";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var isAsyncIterable = (function isAsyncIterable(input) {
	return (
		typeof input === 'object' &&
		input !== null &&
		(input[Symbol.toStringTag] === 'AsyncGenerator' ||
			Symbol.asyncIterator in input)
	)
});

var decodeEachChunk = (async function(parts, onNext, onError) {
    for await (const part of parts) {
			if (!part.json) {
        // console.log("no json from part", part);
				// onError(new Error('Failed to parse part as json.'));
				break;
			}

			onNext(part.body);
    }
  });

function getChunks(response, onNext, onError, onComplete) {
  var __x = Browser.meros(response);
  return Js_promise.then_((function (parts) {
                if (isAsyncIterable(parts)) {
                  var __x = decodeEachChunk(parts, onNext, onError);
                  return Js_promise.then_((function () {
                                onComplete();
                                return Promise.resolve();
                              }), __x);
                }
                try {
                  var __x$1 = parts.json();
                  return Js_promise.then_((function (json) {
                                onNext(json);
                                onComplete();
                                return Promise.resolve();
                              }), __x$1);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  if (err.RE_EXN_ID === Js_exn.$$Error) {
                    onError(err._1);
                    return Promise.resolve();
                  }
                  throw err;
                }
              }), __x);
}

export {
  getChunks ,
}
/* decodeEachChunk Not a pure module */
