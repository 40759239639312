// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NetworkUtils from "../../server/NetworkUtils.re.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.re.mjs";
import * as RelayRuntime from "relay-runtime";

var network = RelayRuntime.Network.create(NetworkUtils.makeFetchQuery());

function makeEnvironmentWithNetwork(network, missingFieldHandlers) {
  return RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(), 50, 21600000), undefined, undefined, missingFieldHandlers, undefined, undefined);
}

var environment = makeEnvironmentWithNetwork(network, undefined);

function makeServer(onQuery, _request) {
  var network = RelayRuntime.Network.create(NetworkUtils.makeServerFetchQuery(onQuery, ({..._request.headers, 'content-type': 'application/json'})));
  return makeEnvironmentWithNetwork(network, undefined);
}

function getRelayEnv(context, ssr) {
  if (ssr) {
    return context.environment;
  } else {
    return environment;
  }
}

export {
  network ,
  makeEnvironmentWithNetwork ,
  environment ,
  makeServer ,
  getRelayEnv ,
}
/* network Not a pure module */
